<template>
  <span class="list-status-text">
    <span v-if="myType" class="status-dot" :class="[myType + '-dot']"></span>
    <span class="status-text">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: "ListStatusText",
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mapList: [
        // 广告列表
        { type: "success", text: "进行中" },
        { type: "info", text: "未开始" },

        { type: "info", text: "已结束" },
        // 订单列表
        { type: "success", text: "已支付" },
        { type: "warning", text: "待支付" },
        { type: "info", text: "已退款" },
        { type: "danger", text: "已取消" },

        { type: "success", text: "已上传" },
        { type: "warning", text: "未上传" },
        // 开启/关闭
        { type: "success", text: "开启" },
        { type: "info", text: "关闭" },

        { type: "success", text: "通过" },
        { type: "danger", text: "不通过" },
        { type: "danger", text: "未通过" },
        { type: "danger", text: "申请不通过" },
        { type: "danger", text: "报名不通过" },

        { type: "warning", text: "待面试" },
        { type: "warning", text: "申请中" },

        { type: "success", text: "已发送" },
        { type: "danger", text: "未发送" },

        {type: 'success', text: '已通过'},
        {type: 'warning', text: '审批中'},
        {type: 'danger', text: '已驳回'},
        {type: 'info', text: '已撤销'},

        {type: 'warning', text: '审核中'},
        {type: 'success', text: '已提现'},

        {type: 'success', text: '已发布'},
        {type: 'danger', text: '未发布'},
      ],
    };
  },
  computed: {
    myType() {
      if (this.type) return this.type;
      let type = "";
      this.mapList.forEach((item) => {
        if (item.text === this.text) {
          type = item.type;
        }
      });
      return type;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-status-text {
  /* 列表状态小圆点 */
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;

    &.warning-dot {
      background-color: $warning;
    }

    &.success-dot {
      background-color: $success;
    }

    &.info-dot {
      background-color: $info;
    }

    &.danger-dot {
      background-color: $danger;
    }
  }

  .status-text {
    color: inherit;
    font-size: inherit;
  }
}
</style>
